import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { selectAuthError, selectIsAuthenticated, selectIsLoadingAuth } from '../store/authSlice';
import LoadingIndicator from './common/LoadingIndicator';
// import { RootState } from '../store/store';
// import { useAppSelector } from '../hooks/reduxHooks';

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const error = useSelector(selectAuthError);
  //const uiLoading = useAppSelector((state: RootState) => state.ui.uiLoading);
  const authLoading = useSelector(selectIsLoadingAuth);
  const navigate = useNavigate();


  const checkAuthentication = useCallback(async () => {
    if(location.pathname === '/login') {
      return;
    }
    if (!isAuthenticated && !authLoading) {
        //console.log('ProtectedRoute: initial authentication check - Authentication failed:', error);
        navigate('/login', { state: { from: location } });
    } else {
      //console.log('ProtectedRoute: authentication check - proceeding');
    }
  }, [isAuthenticated, authLoading, navigate, location]);


  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);


  if (authLoading) {
    return (
      <LoadingIndicator variant="pulse" />
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          Authentication Error: {error}
        </Typography>
      </Box>
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;