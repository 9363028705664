import React, { useState } from 'react'
import { Box, Button, Divider, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { OAUTH_PROVIDERS } from './OAuthProviders'
import { supabase } from '../../../utils/supabaseClient'
import { useDispatch } from 'react-redux'
import { setError, clearError } from '../../../store/authSlice'
import { AppDispatch } from '../../../store/store'

const OAuthButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}))

const OAuthButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  width: '48px',
  height: '48px',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  
  '& svg': {
    fontSize: '24px',
    transition: theme.animation.transition.short,
  },
  
  // Default state
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.divider}`,
  
  // Animation
  transition: theme.animation.transition.short,
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'currentColor',
    opacity: 0.1,
    transition: theme.animation.transition.short,
  },
  
  '&:hover': {
    transform: theme.animation.hover.translateY,
    '&::before': {
      opacity: 0.15,
    },
  },
  
  '&:active': {
    transform: 'translateY(1px)',
  },
  
  // Disabled state
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: 'transparent',
    '& svg': {
      opacity: 0.5,
    }
  }
}))

const OrDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(3, 0),
  
  '& hr': {
    flexGrow: 1,
    borderColor: theme.palette.divider,
  },
  
  '& span': {
    margin: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.fontFamily,
  }
}))

const OAuthButtons: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [loadingProvider, setLoadingProvider] = useState<string | null>(null)

  const handleOAuthLogin = async (provider: string) => {
    try {
      dispatch(clearError())
      setLoadingProvider(provider)
      
      const { error } = await supabase.auth.signInWithOAuth({
        provider: provider as any,
        options: {
          redirectTo: window.location.origin
        }
      })
      
      if (error) throw error
    } catch (error: any) {
      console.error('OAuth error:', error)
      dispatch(setError(error.message || 'Failed to authenticate with provider'))
    } finally {
      setLoadingProvider(null)
    }
  }

  return (
    <>
      <OAuthButtonsContainer>
        {OAUTH_PROVIDERS.map((provider) => {
          const Icon = provider.icon
          const isLoading = loadingProvider === provider.id
          
          return (
            <OAuthButton
              key={provider.id}
              onClick={() => handleOAuthLogin(provider.id)}
              disabled={!!loadingProvider}
              aria-label={`Continue with ${provider.name}`}
              sx={{
                color: provider.iconColor,
                '&:hover': {
                  borderColor: provider.color,
                  backgroundColor: provider.lightColor,
                }
              }}
            >
              {isLoading ? (
                <CircularProgress 
                  size={20} 
                  sx={{ color: 'inherit' }}
                />
              ) : (
                <Icon />
              )}
            </OAuthButton>
          )
        })}
      </OAuthButtonsContainer>
      
      <OrDivider>
        <Divider />
        <Typography component="span">or</Typography>
        <Divider />
      </OrDivider>
    </>
  )
}

export default OAuthButtons