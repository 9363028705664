import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton, HiddenSpan } from '../common/StyledAuthComponents';
import { Box, CircularProgress, Fade } from '@mui/material';
import { supabase } from '../../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../../store/authSlice';
import { AppDispatch } from '../../../store/store';
import SuccessNotification from '../common/SuccessNotification';
import ErrorNotification from '../common/ErrorNotification';
import validator from 'validator';
import OAuthButtons from '../common/OAuthButtons';

interface LoginFormProps {
  onSwitchToRegister: () => void;
  onSwitchToRecover: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSwitchToRegister, onSwitchToRecover }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ email?: string; password?: string }>({});
  //const [showPassword, setShowPassword] = useState(false);

  const sanitizeEmail = (email: string) => email.toLowerCase().trim();

  const validateForm = (): boolean => {
    const errors: { email?: string; password?: string } = {};
    const emailValid = validator.isEmail(sanitizeEmail(email), { allow_underscores: true });

    if (!email) {
      errors.email = 'Email is required';
    } else if (!emailValid) {
      errors.email = 'Please enter a valid email address';
    }

    if (!password) {
      errors.password = 'Password is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessMsg('');
    dispatch(clearError());
    setValidationErrors({});

    if (!validateForm()) return;

    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: sanitizeEmail(email),
        password,
      });

      if (error) {
        dispatch(setError(error.message));
        setErrorMsg(error.message);
      } else if (data.session) {
        setSuccessMsg('Login successful! Redirecting...');
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={(theme) => ({
        position: 'relative',
        transition: theme.animation.transition.medium,
      })}
    >
      <AuthTitle>Welcome Back</AuthTitle>

      <Fade in={!!errorMsg} timeout={300}>
        <Box>{errorMsg && <ErrorNotification message={errorMsg} />}</Box>
      </Fade>

      <Fade in={!!successMsg} timeout={300}>
        <Box>{successMsg && <SuccessNotification message={successMsg} />}</Box>
      </Fade>

      <OAuthButtons />

      <Box
        sx={(theme) => ({
          position: 'relative',
          opacity: loading ? 0.7 : 1,
          pointerEvents: loading ? 'none' : 'auto',
          transition: theme.animation.transition.medium,
        })}
      >
        <AuthTextField
          label="Email"
          variant="outlined"
          type="email"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMsg('');
            setValidationErrors({});
          }}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          disabled={loading}
          autoComplete="email"
          InputProps={{
            sx: (theme) => ({
              transition: theme.animation.transition.medium,
            }),
          }}
        />

        <AuthTextField
          label="Password"
          variant="outlined"
          type="password"
          required
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorMsg('');
            setValidationErrors({});
          }}
          error={!!validationErrors.password}
          helperText={validationErrors.password}
          disabled={loading}
          autoComplete="current-password"
          InputProps={{
            // endAdornment: (
            //   <PasswordVisibilityButton
            //     aria-label="toggle password visibility"
            //     onClick={() => setShowPassword(!showPassword)}
            //     edge="end"
            //     disabled={loading}
            //     tabIndex={-1}
            //   >
            //     {showPassword ? <VisibilityOff /> : <Visibility />}
            //   </PasswordVisibilityButton>
            // ),
            sx: (theme) => ({
              transition: theme.animation.transition.medium,
            }),
          }}
        />

        <AuthButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={(theme) => ({
            position: 'relative',
            '& .MuiCircularProgress-root': {
              position: 'absolute',
              left: '50%',
              marginLeft: '-12px',
            },
          })}
        >
          {loading ? (
            <>
              <CircularProgress size={24} color="inherit" />
              <HiddenSpan>Login</HiddenSpan>
            </>
          ) : (
            'Login'
          )}
        </AuthButton>

        <AuthLink>
          <AuthLinkButton onClick={onSwitchToRecover}>
            Forgot Password?
          </AuthLinkButton>
        </AuthLink>

        <AuthLink>
          Don't have an account?{' '}
          <AuthLinkButton onClick={onSwitchToRegister}>
            Register
          </AuthLinkButton>
        </AuthLink>
      </Box>
    </Box>
  );
};

export default LoginForm;