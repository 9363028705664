import React from 'react';
import { Alert, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ErrorNotificationProps {
  message: string;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.error.main}15`, // 15% opacity
  color: theme.palette.error.dark,
  border: `1px solid ${theme.palette.error.main}33`, // 33% opacity
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
  
  '& .MuiAlert-icon': {
    color: theme.palette.error.main,
  },
  
  '& .MuiAlert-message': {
    padding: '6px 0',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
  },
  
  // Animation
  transition: theme.animation.transition.short,
  
  '&:hover': {
    backgroundColor: `${theme.palette.error.main}20`, // 20% opacity
  }
}));

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message }) => {
  return (
    <Fade in timeout={300}>
      <StyledAlert severity="error">
        {message}
      </StyledAlert>
    </Fade>
  );
};

export default ErrorNotification;