import { Provider } from '@supabase/supabase-js'
import GoogleIcon from '@mui/icons-material/Google'
import { SiDiscord, SiSlack } from 'react-icons/si'

export type OAuthProvider = {
  id: Provider
  name: string
  icon: React.ComponentType
  color: string
  hoverColor: string
  lightColor: string
  iconColor: string
}

export const OAUTH_PROVIDERS: OAuthProvider[] = [
  {
    id: 'google',
    name: 'Google',
    icon: GoogleIcon,
    color: '#DB4437',
    hoverColor: '#C13B2F',
    lightColor: '#FFEEED',
    iconColor: '#DB4437'
  },
  {
    id: 'discord',
    name: 'Discord',
    icon: SiDiscord,
    color: '#7289DA',
    hoverColor: '#677BC4',
    lightColor: '#F0F2FC',
    iconColor: '#7289DA'
  },
  {
    id: 'slack',
    name: 'Slack',
    icon: SiSlack,
    color: '#4A154B',
    hoverColor: '#3E1140',
    lightColor: '#F7F0F7',
    iconColor: '#4A154B'
  }
]