import { Chat, Message, MessageSenderType, Model, serializeDate } from '../types/types';
import { format } from 'date-fns';

export const sortChatsByLastMessageTime = (chats: Chat[]): Chat[] => {
    return [...chats].sort((a, b) => {
      const timeA = a.lastMessageTime ? new Date(a.lastMessageTime).getTime() : 0;
      const timeB = b.lastMessageTime ? new Date(b.lastMessageTime).getTime() : 0;
      return timeB - timeA;
    });
  };

export const formatDate = (date: string | undefined) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';
    
    const now = new Date();
    if (dateObj.toDateString() === now.toDateString()) {
        return format(dateObj, 'h:mm a');
    } else if (dateObj.getFullYear() === now.getFullYear()) {
        return format(dateObj, 'MMM d');
    } else {
        return format(dateObj, 'dd/MM/yy');
    }
};

export const getModelName = (modelId: string, models: Model[]) => {
    const model = models.find(m => m.id === modelId);
    return model ? model.name : modelId;
};

export const updateChatWithNewMessage = (chat: Chat, newMessage: Message): Chat => {
    return {
      ...chat,
      messages: [...chat.messages, newMessage],
      totalTokenCount: (chat.totalTokenCount || 0) + (newMessage.CalculatedTokenCount || 0),
      totalChatCost: (chat.totalChatCost || 0) + (newMessage.totalMessageCost || 0),
      updatedAt: serializeDate(new Date()) || '',
      lastMessage: newMessage,
      lastMessageTime: newMessage.createdAt || '',
    };
};

export const createNewUserMessage = (content: string, modelId: string, messageId?: string): Message => {
    const now = serializeDate(new Date());
    return {
        _id: messageId || 'dummy',
        content,
        isUser: true,
        senderId: 'user',
        senderType: MessageSenderType.USER,
        toModelId: modelId,
        createdAt: now || '',
        updatedAt: now || '',
        CalculatedTokenCount: 0,
        contentType: 'text',
        isEdited: false,
        generationId: 'dummy',
        isComplete: true,
    };
};

export const createNewAIMessage = (content: string, modelId: string, messageId?: string): Message => {
    const now = serializeDate(new Date());
    return {
        _id: messageId || '',
        content: content,
        isUser: false,
        senderId: modelId,
        senderType: MessageSenderType.ASSISTANT,
        toModelId: modelId,
        createdAt: now || '',
        updatedAt: now || '',
        CalculatedTokenCount: 0,
        generationId: '',
        contentType: 'text',
        isEdited: false,
        isComplete: false,
    };
};

export const updateAIMessageWithMetadata = (message: Message, metadata: any): Message => {
    return {
        ...message,
        generationId: metadata.id,
        tokensPrompt: metadata.native_tokens_prompt,
        tokensCompletion: metadata.native_tokens_completion,
        totalMessageCost: metadata.total_cost,
        CalculatedTokenCount: metadata.tokens_completion,
        updatedAt: serializeDate(new Date()) || '',
        isComplete: true,
    };
};

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const isMobileDevice = () => window.innerWidth <= 768;

export const calculateContextUsage = (messages: Message[]): number => {
    return messages.reduce((total, message) => total + (message.tokensCompletion || 0), 0);
};

export const truncateString = (str: string, maxLength: number): string => {
    if (str.length <= maxLength) return str;
    return str.slice(0, maxLength - 3) + '...';
};

export const isValidDate = (date: string): boolean => {
    return !isNaN(new Date(date).getTime());
};

export const waitForCondition = async (
    condition: () => Promise<boolean>,
    maxAttempts = 10,
    interval = 500
): Promise<boolean> => {
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
        if (await condition()) {
            return true;
        }
        await delay(interval);
    }
    return false;
};

export const formatCurrency = (amount: number): string => {
    return amount.toFixed(6);
};

export const sanitizeInput = (input: string): string => {
    // Implement input sanitization logic here
    return input;
};

export const debounce = <F extends (...args: any[]) => any>(
    func: F,
    waitFor: number
) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    return (...args: Parameters<F>): Promise<ReturnType<F>> => {
        if (timeout) {
            clearTimeout(timeout);
        }

        return new Promise(resolve => {
            timeout = setTimeout(() => resolve(func(...args)), waitFor);
        });
    };
};

export const generateUniqueId = (): string => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

/**
 * Gets the model ID from the last AI message in a chat
 * @param chat The chat to analyze
 * @returns The model ID or null if no AI messages are found
 */
export const getLastAIMessageModelId = (chat: Chat): string | null => {
  if (!chat.messages?.length) return null;
  
  const lastAIMessage = [...chat.messages]
    .reverse()
    .find(msg => msg.senderType === MessageSenderType.ASSISTANT && msg.isComplete && msg.isDeleted === false);
    
  return lastAIMessage?.toModelId || null;
};