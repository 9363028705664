import { axiosInstance } from "./axiosConfig";
import type { Chat, Message } from "../types/types";
import { createParser, type ParsedEvent, type ReconnectInterval } from "eventsource-parser";
import { CONFIG } from "../config";
import { getStore } from "../utils/storeInjector";
import { ErrorCode } from "../errors/errorCodes";
import { MappedError } from "../errors/errorUtils";
import { ApiErrorResponse } from '../errors/error';
import { mapErrorResponse } from '../errors/errorUtils';

const BASE_URL = CONFIG.API_URL;

/**
 * Sends a message to the chat API and streams the response.
 * @param message - The message to send
 * @param model - The AI model to use
 * @param chatId - The ID of the chat
 * @param stream - Whether to use streaming response
 * @param overrideCreditCheck - Whether to override credit check
 * @param retryFlag - Whether this is a retry attempt
 * @param onChunk - Callback function to handle each chunk of the response
 * @param signal - Optional AbortSignal to cancel the request
 * @returns Object containing updated chat, metadata, and message IDs
 * @throws {ValidationError} If message or model parameters are invalid
 * @throws {NotFoundError} If chat is not found
 * @throws {ExternalServiceError} If AI service fails
 * @throws {ServerError} For unexpected errors
 * @throws {CreditError} If user has insufficient credits
 */
export const sendMessage = async (
  message: string,
  model: string,
  chatId: string,
  stream: boolean,
  overrideCreditCheck: boolean,
  retryFlag: boolean,
  onChunk: (chunk: string) => void,
  abortSignal?: AbortSignal
): Promise<{
  chat: Chat;
  generationMetadata: any;
  userMessageId: string;
  aiMessageId: string;
  isTrimmed: boolean;
  newCreditBalance: string;
  newTitle: string;
}> => {

  try {
    const token = getStore().getState().auth.accessToken;
    const response = await fetch(`${BASE_URL}/chats/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message,
        model,
        chatId,
        stream,
        overrideCreditCheck,
        retryFlag,
      }),
      signal: abortSignal,
    });

    if (!response.ok) {
      
      // Try to parse error response
      let errorResponse: ApiErrorResponse;
      
      try {
        const errorData = await response.json();
        errorResponse = {
          status: response.status,
          error: errorData.error || 'UnknownError',
          message: errorData.message || `HTTP error! status: ${response.status}`,
          errorCode: errorData.errorCode || ErrorCode.SERVER_ERROR,
          details: errorData.details || undefined
        };
      } catch (e) {
        // If we can't parse the error response, create a generic error
        errorResponse = {
          status: response.status,
          error: 'ParseError',
          message: `Failed to parse error response. Status: ${response.status}`,
          errorCode: ErrorCode.SERVER_ERROR,
          details: undefined
        };
      }

      // Map the error response to include frontend configurations
      const mappedError = mapErrorResponse(errorResponse);
      throw mappedError;
    }

    let generationMetadata: any = null;
    let userMessageId: string = "";
    let aiMessageId: string = "";
    let isTrimmed: boolean = false;
    let newCreditBalance: string = "";
    let newTitle: string = "";

    if (!stream) {
      // Handle non-streaming response
      const responseData = await response.json();
      onChunk(responseData.content);
      generationMetadata = responseData.generationMetadata;
      userMessageId = responseData.userMessageId;
      aiMessageId = responseData.aiMessageId;
      isTrimmed = responseData.isTrimmed;
      newCreditBalance = responseData.newCreditBalance;
      newTitle = responseData.newTitle;

      return {
        chat: responseData.updatedChat
          ? {
              ...responseData.updatedChat,
              totalTokenCount:
                responseData.updatedChat.totalTokenCount || undefined,
              totalChatCost:
                responseData.updatedChat.totalChatCost || undefined,
              updatedAt: responseData.updatedChat.updatedAt
                ? new Date(responseData.updatedChat.updatedAt)
                : undefined,
              creditWarning: responseData.updatedChat.creditWarning
            }
          : null,
        generationMetadata,
        userMessageId,
        aiMessageId,
        isTrimmed,
        newCreditBalance,
        newTitle,
      };
    }

    // Handle streaming response
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error("Failed to obtain reader from response body.");
    }

    const decoder = new TextDecoder();
    let updatedChat: Chat | null = null;

    const parser = createParser((event: ParsedEvent | ReconnectInterval) => {
      if (event.type === "event") {
        if (event.data === "[DONE]") {
          console.log("sendMessage: Stream done.");
          return;
        }
        try {
          const parsed = JSON.parse(event.data);
          if (parsed.error) {
            const parsedError: ApiErrorResponse = {
              status: parsed.status,
              error: 'StreamError',
              message: parsed.error,
              errorCode: parsed.errorCode as ErrorCode,
              details: parsed.details
            };
            throw mapErrorResponse(parsedError);
          }
          if (parsed.done && parsed.updatedChat) {
            updatedChat = {
              ...parsed.updatedChat,
              totalTokenCount: parsed.updatedChat.totalTokenCount || undefined,
              totalChatCost: parsed.updatedChat.totalChatCost || undefined,
              updatedAt: parsed.updatedChat.updatedAt
                ? new Date(parsed.updatedChat.updatedAt)
                : undefined,
              creditWarning: parsed.updatedChat.creditWarning
            };
            generationMetadata = parsed.generationMetadata;
            userMessageId = parsed.userMessageId;
            aiMessageId = parsed.aiMessageId;
            isTrimmed = parsed.isTrimmed;
            newCreditBalance = parsed.newCreditBalance;
            newTitle = parsed.newTitle;

          } else if (parsed.content) {
            onChunk(parsed.content);
          } else if (parsed.error) {
            //console.error("sendMessage: Server-side error:", parsed.error);
            throw new Error(parsed.error);
          }
        } catch (e) {
            throw e;
        }
      } else if (event.type === "reconnect-interval") {
        console.log(`sendMessage: Reconnect interval set to ${event.value} ms`);
        // Handle reconnect interval if necessary
      }
    });

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const chunk = decoder.decode(value, { stream: true });
      parser.feed(chunk);
    }

    return {
      chat: updatedChat!,
      generationMetadata,
      userMessageId,
      aiMessageId,
      isTrimmed,
      newCreditBalance,
      newTitle,
    };
  } catch (error) {
    // Handle AbortError
    if (error instanceof Error && error.name === "AbortError") {
      throw error;
    }

    // If it's already a MappedError (from our error handling above), just rethrow it
    if ((error as MappedError).errorCode) {
      throw error;
    }

    // For any other unexpected errors, create a generic mapped error
    const genericError: ApiErrorResponse = {
      status: 500,
      error: 'UnexpectedError',
      message: error instanceof Error ? error.message : 'An unexpected error occurred',
      errorCode: ErrorCode.SERVER_ERROR
    };

    throw mapErrorResponse(genericError);
  }
};

/**
 * Creates a new chat with the specified title
 * @param title - The title for the new chat
 * @returns The newly created chat object
 * @throws {ValidationError} If title is invalid
 * @throws {ServerError} For unexpected errors
 */
export const createChat = async (title: string, folderId?: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.post("/chats/new", { title, folderId });
    const chat = response.data;
    return {
      ...chat,
      createdAt: chat.createdAt ? new Date(chat.createdAt).toISOString() : "",
      updatedAt: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : "",
      messages: chat.messages || [],
    };
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves all chats for the current user
 * @returns Array of chats sorted by last message time
 * @throws {ServerError} For unexpected errors
 */
export const getAllChats = async (): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get("/chats");
    const chats = response.data;
    const processedChats = chats.map((chat: Chat) => ({
      ...chat,
      messages: chat.messages || [],
      lastMessage:
        chat.lastMessage ||
        (chat.messages && chat.messages.length > 0
          ? chat.messages[chat.messages.length - 1]
          : null),
      lastMessageTime: chat.lastMessageTime
        ? new Date(chat.lastMessageTime).toISOString()
        : chat.messages[chat.messages.length - 1]?.updatedAt
        ? new Date(
            chat.messages[chat.messages.length - 1].updatedAt || ""
          ).toISOString()
        : "",
    })) as Chat[];

    return processedChats.sort((a, b) => {
      const timeA = a.lastMessageTime ? new Date(a.lastMessageTime).getTime() : 0;
      const timeB = b.lastMessageTime ? new Date(b.lastMessageTime).getTime() : 0;
      return timeB - timeA;
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves a specific chat by ID
 * @param chatId - The ID of the chat to retrieve
 * @returns The requested chat object
 * @throws {NotFoundError} If chat doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const getChat = async (chatId: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.get(`/chats/${chatId}`);
    const chat = response.data;
    return {
      ...chat,
      tags: chat.tags || undefined,
      folderId: chat.folderId || undefined,
      totalTokenCount: chat.totalTokenCount || undefined,
      totalChatCost: chat.totalChatCost || undefined,
      createdAt: chat.createdAt ? new Date(chat.createdAt).toISOString() : "",
      updatedAt: chat.updatedAt ? new Date(chat.updatedAt).toISOString() : "",
      messages: chat.messages || [],
    } as Chat;
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves messages for a specific chat
 * @param chatId - The ID of the chat
 * @returns Array of messages in the chat
 * @throws {NotFoundError} If chat doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const getChatMessages = async (chatId: string): Promise<Message[]> => {
  try {
    const response = await axiosInstance.get(`/chats/${chatId}/messages`);
    return response.data.map((message: any) => ({
      _id: message._id,
      senderId: message.senderId,
      toModelId: message.toModelId,
      contentType: message.contentType,
      content: message.content,
      isUser:
        message.isUser !== undefined
          ? message.isUser
          : message.senderId === "user",
      attachments: message.attachments,
      isEdited: message.isEdited,
      generationId: message.generationId,
      CalculatedTokenCount: message.CalculatedTokenCount,
      tokensPrompt: message.tokensPrompt,
      tokensCompletion: message.tokensCompletion,
      totalMessageCost: message.totalMessageCost,
      voiceInput: message.voiceInput,
      createdAt: message.createdAt
        ? new Date(message.createdAt).toISOString()
        : "",
      updatedAt: message.updatedAt
        ? new Date(message.updatedAt).toISOString()
        : "",
    })) as Message[];
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a specific chat
 * @param chatId - The ID of the chat to delete
 * @throws {NotFoundError} If chat doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const deleteChat = async (chatId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/chats/${chatId}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Renames a specific chat
 * @param chatId - The ID of the chat to rename
 * @param title - The new title for the chat
 * @returns The updated chat object
 * @throws {NotFoundError} If chat doesn't exist
 * @throws {ValidationError} If title is invalid
 * @throws {ServerError} For unexpected errors
 */
export const renameChat = async (chatId: string, title: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.put(`/chats/${chatId}/rename`, {
      title,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a specific message from a chat
 * @param chatId - The ID of the chat
 * @param messageId - The ID of the message to delete
 * @param retryFlag - Whether this is a retry attempt
 * @returns The updated chat object
 * @throws {NotFoundError} If chat or message doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const deleteMessage = async (
  chatId: string,
  messageId: string,
  retryFlag: boolean
): Promise<Chat> => {
  try {
    const response = await axiosInstance.delete(
      `/chats/${chatId}/messages/${messageId}`,
      { params: { chatId, messageId, retryFlag } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Moves a chat to a specific folder
 * @param chatId - The ID of the chat to move
 * @param folderId - The ID of the folder to move the chat to
 * @throws {NotFoundError} If chat or folder doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const moveChatToFolder = async (chatId: string, folderId: string | undefined): Promise<void> => {
  try {
    await axiosInstance.put(`/chats/${chatId}/move`, { chatId, folderId });
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new chat in a specific folder
 * @param title - The title for the new chat
 * @returns The newly created chat object
 * @throws {ValidationError} If title is invalid
 * @throws {NotFoundError} If folder doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const createChatInFolderApi = async (title: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(`/chats/new`, { title });
    return response.data;
  } catch (error) {
    throw error;
  }
};
