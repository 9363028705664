import {Suspense, lazy, useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AuthPage from './components/auth/AuthPage';
import ProtectedRoute from './components/ProtectedRoute';
import { SocketProvider } from './contexts/SocketProvider';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store/store';
import { getSupabaseUser, clearAuthState, updateAccessToken } from './store/authSlice';
import { getStore } from './utils/storeInjector';
import { supabase } from './utils/supabaseClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MathJaxContextProvider from './components/mainScreen/chat/context/MathJaxContextProvider';
import SettingsLayout from './components/settings/SettingsLayout';
import { openSettings } from './store/settingsSlice';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LoadingIndicator from './components/common/LoadingIndicator';

const MainChatScreen = lazy(() => import('./components/mainScreen/MainScreen'));

// Lazy load settings components
// const Profile = lazy(() => import('./components/settings/pages/Profile'));
// const Account = lazy(() => import('./components/settings/pages/Account'));
// const Notifications = lazy(() => import('./components/settings/pages/Notifications'));
// const Usage = lazy(() => import('./components/settings/pages/Usage'));
// const Billing = lazy(() => import('./components/settings/pages/Billing'));
// const FAQ = lazy(() => import('./components/settings/pages/FAQ'));
// const Documentation = lazy(() => import('./components/settings/pages/Documentation'));
// const Activity = lazy(() => import('./components/settings/pages/Activity'));
// const Transfer = lazy(() => import('./components/settings/pages/Transfer'));
// const Support = lazy(() => import('./components/settings/pages/Support'));

function App() {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  // Check if settings should be open based on URL
  useEffect(() => {
    if (location.pathname.startsWith('/settings')) {
      dispatch(openSettings(location.pathname));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    const checkUser = async () => {
      const user = await getSupabaseUser();
      if(user){
        const { data, error } = await supabase.auth.getSession();
        if(data.session && !error){
          getStore().dispatch(updateAccessToken({
            accessToken: data.session.access_token,
            expiresAt: data.session.expires_at!,
          }));
        }
      } else {
        getStore().dispatch(clearAuthState());
      }
    };
    checkUser();

    return () => {
      getStore().dispatch(clearAuthState());
    };
  }, []);

  return (
    <MathJaxContextProvider>
      <DndProvider backend={HTML5Backend}>
        <SocketProvider>
          <Suspense fallback={<LoadingIndicator variant="pulse" />}>
            <Routes>
              <Route path="/*" element={
                isAuthenticated ? (
                  <ProtectedRoute>
                    <>
                      <MainChatScreen />
                      <Routes>
                        <Route path="settings/*" element={<SettingsLayout />} />
                      </Routes>
                    </>
                  </ProtectedRoute>
                ) : (
                  <AuthPage />
                )
              } />
              <Route path="/login" element={<AuthPage />} />
              <Route path="/register" element={<AuthPage />} />
              <Route path="/forgot-password" element={<AuthPage />} />
              <Route path="/reset-password" element={<AuthPage />} />
              
            </Routes>
          </Suspense>
        </SocketProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </DndProvider>
    </MathJaxContextProvider>
  );
}

export default App;