import { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import {
  AuthPageContainer,
  AuthCard,
} from './common/StyledAuthComponents';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStore } from '../../utils/storeInjector';

// Import custom authentication forms
import LoginForm from './forms/LoginForm';
import RegistrationForm from './forms/RegistrationForm';
import PasswordRecoveryForm from './forms/PasswordRecoveryForm';
import ResetPasswordForm from './forms/ResetPasswordForm';

const AuthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authStore = getStore().getState().auth;

  // State to manage the current form
  const [currentForm, setCurrentForm] = useState<'login' | 'register' | 'recover' | 'resetPassword'>('login');

  useEffect(() => {
    // Handle only navigation-related auth events
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'SIGNED_IN':

          if(authStore.session === session){
            console.log('AuthPage: session already in state');
            break;
          }
          if (session?.user) {
            // Check if this is an OAuth callback
            const params = new URLSearchParams(window.location.search)
            const provider = params.get('provider')
            
            if(provider){
              console.log('AuthPage: OAuth callback detected');
            }
            // Defer navigation to avoid blocking the callback
            setTimeout(() => {
              // Check if we have a valid from location, otherwise try pathname, then default to root
              let from = '/';
              if (location.state && (location.state as any).from) {
                from = (location.state as any).from.pathname;
              } else if (location.pathname !== '/login' && 
                        location.pathname !== '/register' && 
                        location.pathname !== '/forgot-password' && 
                        location.pathname !== '/reset-password') {
                from = location.pathname;
              }
              navigate(from, { replace: true });
            }, 0);
          }
          break;

        case 'PASSWORD_RECOVERY':
          break;

        case 'SIGNED_OUT':
          break;
      }
    });

    // Check if we're on the reset password route
    if (location.pathname === '/reset-password') {
      setCurrentForm('resetPassword');
    } else if (location.pathname === '/forgot-password') {
      setCurrentForm('recover');
    } else if (location.pathname === '/register') {
      setCurrentForm('register');
    } else if (location.pathname === '/login') {
      setCurrentForm('login');
    }

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [navigate, location, authStore]);

  
  // Handler functions to switch forms
  const handleSwitchToRegister = () => {
    setCurrentForm('register');
    navigate('/register');
  };

  const handleSwitchToLogin = () => {
    setCurrentForm('login');
    navigate('/login');
  };

  const handleSwitchToRecover = () => {
    setCurrentForm('recover');
    navigate('/forgot-password');
  };

  return (
    <AuthPageContainer>
      <AuthCard>
        {currentForm === 'login' && (
          <LoginForm
            onSwitchToRegister={handleSwitchToRegister}
            onSwitchToRecover={handleSwitchToRecover}
          />
        )}
        {currentForm === 'register' && (
          <RegistrationForm
            onSwitchToLogin={handleSwitchToLogin}
          />
        )}
        {currentForm === 'recover' && (
          <PasswordRecoveryForm
            onSwitchToLogin={handleSwitchToLogin}
          />
        )}
        {currentForm === 'resetPassword' && (
          <ResetPasswordForm
            onSwitchToLogin={handleSwitchToLogin}
          />
        )}
      </AuthCard>
    </AuthPageContainer>
  );
};

export default AuthPage;