import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton, HiddenSpan } from '../common/StyledAuthComponents';
import { Box, CircularProgress, Fade } from '@mui/material';
import { supabase } from '../../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../../store/authSlice';
import { AppDispatch } from '../../../store/store';
import ErrorNotification from '../common/ErrorNotification';
import SuccessNotification from '../common/SuccessNotification';
import validator from 'validator';

interface PasswordRecoveryFormProps {
  onSwitchToLogin: () => void;
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [validationErrors, setValidationErrors] = useState<{ email?: string }>({});
  const [loading, setLoading] = useState(false);

  const sanitizeEmail = (email: string) => email.toLowerCase().trim();

  const validateForm = (): boolean => {
    const errors: { email?: string } = {};
    const emailValid = validator.isEmail(sanitizeEmail(email), { allow_underscores: true });

    if (!email) {
      errors.email = 'Email is required';
    } else if (!emailValid) {
      errors.email = 'Please enter a valid email address';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handlePasswordRecovery = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setValidationErrors({});
    setErrorMsg('');
    setSuccessMsg('');

    if (!validateForm()) return;

    setLoading(true);

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(sanitizeEmail(email), {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) {
        dispatch(setError(error.message));
        setErrorMsg(error.message);
      } else {
        setSuccessMsg('Recovery email sent! Please check your inbox for further instructions.');
      }
    } catch (error) {
      console.error('Password recovery error:', error);
      setErrorMsg('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handlePasswordRecovery}
      sx={(theme) => ({
        position: 'relative',
        transition: theme.animation.transition.medium,
      })}
    >
      <AuthTitle>Reset Password</AuthTitle>

      <Fade in={!!errorMsg} timeout={300}>
        <Box>{errorMsg && <ErrorNotification message={errorMsg} />}</Box>
      </Fade>

      {!successMsg ? (
        <Box
          sx={(theme) => ({
            position: 'relative',
            opacity: loading ? 0.7 : 1,
            pointerEvents: loading ? 'none' : 'auto',
            transition: theme.animation.transition.medium,
          })}
        >
          <Box sx={{ mb: 2, color: 'text.secondary' }}>
            Enter your email address and we'll send you instructions to reset your password.
          </Box>

          <AuthTextField
            label="Email"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMsg('');
              setValidationErrors({});
            }}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
            disabled={loading}
            autoComplete="email"
            InputProps={{
              sx: (theme) => ({
                transition: theme.animation.transition.medium,
              }),
            }}
          />

          <AuthButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={(theme) => ({
              position: 'relative',
              '& .MuiCircularProgress-root': {
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px',
              },
            })}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                <HiddenSpan>Send Recovery Email</HiddenSpan>
              </>
            ) : (
              'Send Recovery Email'
            )}
          </AuthButton>

          <AuthLink>
            <AuthLinkButton onClick={onSwitchToLogin}>
              Back to Login
            </AuthLinkButton>
          </AuthLink>
        </Box>
      ) : (
        <Fade in timeout={300}>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <SuccessNotification message={successMsg} />
            <AuthButton
              variant="outlined"
              color="primary"
              onClick={onSwitchToLogin}
              sx={{ mt: 2 }}
            >
              Return to Login
            </AuthButton>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default PasswordRecoveryForm;