import { ApiErrorResponse, ErrorConfig, ErrorSeverity } from './error';
import { errorConfig } from './errorConfig';

/**
 * MappedError extends the original ApiErrorResponse with additional frontend configurations.
 */
export interface MappedError extends ApiErrorResponse {
  severity: ErrorSeverity;
  retryable: boolean;
  userActionRequired: boolean;
  clearanceAction?: 'RETRY' | 'REDIRECT' | 'REFRESH' | 'CONTACT_SUPPORT';
}

/**
 * Maps a backend ApiErrorResponse to a frontend MappedError using the errorConfig.
 * @param errorResponse - The error response received from the backend.
 * @returns A MappedError object containing both the error details and frontend configurations.
 */
export function mapErrorResponse(errorResponse: ApiErrorResponse): MappedError {
  const { errorCode } = errorResponse;

  // Retrieve the corresponding ErrorConfig from errorConfig
  const config: ErrorConfig | undefined = errorConfig[errorCode];

  if (!config) {
    // If the errorCode is not defined in errorConfig, default configurations can be applied
    return {
      ...errorResponse,
      severity: ErrorSeverity.HIGH,
      retryable: false,
      userActionRequired: true,
      clearanceAction: 'CONTACT_SUPPORT',
    };
  }

  return {
    ...errorResponse,
    severity: config.severity,
    retryable: config.retryable,
    userActionRequired: config.userActionRequired,
    clearanceAction: config.clearanceAction,
  };
}