import { createClient } from '@supabase/supabase-js';
import { getStore } from './storeInjector';
import { loginDirect, updateAccessToken, setCredentials } from '../store/authSlice';
import { logout as logoutApi} from '../api/auth';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// console.log('Supabase URL:', supabaseUrl);
// console.log('Environment:', process.env.NODE_ENV);

if (!supabaseUrl || !supabaseAnonKey) {
    console.error('Missing Supabase environment variables:', {
        url: !!supabaseUrl,
        key: !!supabaseAnonKey
    });
    throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

supabase.auth.onAuthStateChange((event, session) => {
    const store = getStore();
    //console.log('SupabaseClient: store', store.getState());
    const sessionInState = store.getState().auth.session;
    
    switch (event) {
        case 'INITIAL_SESSION':
            break;

        case 'SIGNED_IN':
            if (!session) {
                console.log('SupabaseClient: no session provided');
                return;
            }

            // Compare relevant session properties instead of direct object comparison
            const isExistingSession = sessionInState?.access_token === session.access_token && 
                                    sessionInState?.refresh_token === session.refresh_token;
            
            if (isExistingSession) {
                console.log('SupabaseClient: session already exists, skipping update');
                return;
            }

            setTimeout(async () => {
                console.log('SupabaseClient: updating session', {
                    existing: sessionInState,
                    new: session
                });
                await store.dispatch(setCredentials({session: session}));
                await store.dispatch(loginDirect(session));
            }, 0);
            break;

        case 'SIGNED_OUT':
            // Clean up local storage and state
            setTimeout(() => {
                console.log('SupabaseClient: signed out');
                logoutApi({cleanupOnly: true});
            }, 0);
            break;

        case 'TOKEN_REFRESHED':
            // Update the in-memory access token and store
            if (session?.access_token) {
                store.dispatch(setCredentials({
                    session: session
                }));
                // setTimeout(() => {
                //     refreshTokenOnServer();
                // }, 0);
            }
            break;

        case 'USER_UPDATED':
            // Update user profile information
            setTimeout(() => {
                if (session) {
                    store.dispatch(updateAccessToken({ 
                        accessToken: session.access_token, 
                        expiresAt: session.expires_at || null 
                    }));
                }
            }, 0);
            break;
    }
});

