import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SettingsState {
  isOpen: boolean;
  currentPath: string | null;
}

const initialState: SettingsState = {
  isOpen: false,
  currentPath: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    openSettings: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.currentPath = action.payload;
    },
    closeSettings: (state) => {
      state.isOpen = false;
      state.currentPath = null;
    },
  },
});

export const { openSettings, closeSettings } = settingsSlice.actions;
export default settingsSlice.reducer;