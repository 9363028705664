import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Folder } from "../types/types";
import { createFolderAPI, deleteFolderAPI, getAllFoldersAPI, renameFolderAPI, moveFolderAPI } from "../api/folders";
import { RootState } from "./store";
import { createSelector } from 'reselect';
import { deleteChat as deleteChatAPI } from "../api/chats";

interface FolderState {
  folders: Folder[];
  loading: boolean;
  error: string | null;
  selectedFolderId: string | null;
  newFolderId: string | null;
}

const initialState: FolderState = {
  folders: [],
  loading: false,
  error: null,
  selectedFolderId: null,
  newFolderId: null,
};

// Async actions
export const fetchFolders = createAsyncThunk(
  "folders/fetchFolders",
  async (_, { rejectWithValue }) => {
    try {
      const folders = await getAllFoldersAPI();
      return folders;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createFolder = createAsyncThunk(
  "folders/createFolder",
  async ({ name, parentFolderId }: { name: string; parentFolderId?: string }, { rejectWithValue }) => {
    try {
      const folder = await createFolderAPI(name, parentFolderId);
      return folder;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const renameFolder = createAsyncThunk(
  "folders/renameFolder",
  async ({ folderId, name }: { folderId: string; name: string }, { rejectWithValue }) => {
    try {
      const folder = await renameFolderAPI(folderId, name);
      return folder;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface DeleteFolderPayload {
  folderId: string;
  affectedFolderIds: string[];
  affectedChatIds: string[];
}

export const deleteFolder = createAsyncThunk(
  "folders/deleteFolder",
  async ({ folderId, affectedFolderIds, affectedChatIds }: DeleteFolderPayload, { rejectWithValue }) => {
    try {
      if(affectedFolderIds.length > 0) {
        for(const id of affectedFolderIds) {
          await deleteFolderAPI(id);
        }
      }
      if(affectedChatIds.length > 0) {
        for(const id of affectedChatIds) {
          await deleteChatAPI(id);
        }
      }
      return folderId;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const moveFolder = createAsyncThunk(
  "folders/moveFolder",
  async (
    { folderId, newParentFolderId }: { folderId: string; newParentFolderId?: string },
    { rejectWithValue }
  ) => {
    try {
      const folder = await moveFolderAPI(folderId, newParentFolderId);
      return folder;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Selectors
export const selectAllFolders = (state: RootState) => state.folders.folders;

export const selectFolderById = (state: RootState, folderId: string) => 
  state.folders.folders.find(folder => folder._id === folderId);

interface FolderWithChildren extends Folder {
  children: FolderWithChildren[];
}

export const selectNestedFolders = createSelector(
  [selectAllFolders],
  (folders): FolderWithChildren[] => {
    const buildNestedStructure = (isTop: boolean): FolderWithChildren[] => {
      return folders
        .filter((folder) => folder.isTopLevel === isTop)
        .map((folder) => ({
          ...folder,
          children: [], // For now, all folders are top-level
        }));
    };
    
    return buildNestedStructure(true);
  }
);

// Slice
const folderSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    setSelectedFolderId: (state, action: PayloadAction<string | null >) => {
      state.selectedFolderId = action.payload;
    },
    setNewFolderId: (state, action: PayloadAction<string | null>) => {
      state.newFolderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Folders
      .addCase(fetchFolders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFolders.fulfilled, (state, action: PayloadAction<Folder[]>) => {
        state.loading = false;
        state.folders = action.payload;
      })
      .addCase(fetchFolders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create Folder
      .addCase(createFolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFolder.fulfilled, (state, action: PayloadAction<Folder>) => {
        state.loading = false;
        state.folders.unshift(action.payload);
        state.newFolderId = action.payload._id;
      })
      .addCase(createFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Rename Folder
      .addCase(renameFolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(renameFolder.fulfilled, (state, action: PayloadAction<Folder>) => {
        state.loading = false;
        const index = state.folders.findIndex((f) => f._id === action.payload._id);
        if (index !== -1) {
          state.folders[index] = action.payload;
        }
      })
      .addCase(renameFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete Folder
      .addCase(deleteFolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFolder.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.folders = state.folders.filter((f) => f._id !== action.payload);
        if (state.selectedFolderId === action.payload) {
          state.selectedFolderId = null;
        }
      })
      .addCase(deleteFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Move Folder
      .addCase(moveFolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(moveFolder.fulfilled, (state, action: PayloadAction<Folder>) => {
        state.loading = false;
        const index = state.folders.findIndex((f) => f._id === action.payload._id);
        if (index !== -1) {
          state.folders[index] = action.payload;
        }
      })
      .addCase(moveFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedFolderId, setNewFolderId } = folderSlice.actions;
export default folderSlice.reducer;