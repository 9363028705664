import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import chatReducer from './chatSlice';
import modelReducer from './modelSlice';
import uiReducer from './uiSlice';
import notificationReducer from './notificationSlice';
import settingsReducer from './settingsSlice';
import folderReducer from './folderSlice';
import { injectStore } from '../utils/storeInjector';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    model: modelReducer,
    ui: uiReducer,
    notifications: notificationReducer,
    settings: settingsReducer,
    folders: folderReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignoredActions: ['chat/setChats', 'chat/setMessages', 'model/setSelectedModel'],
        // ignoredActionPaths: ['payload.createdAt', 'payload.updatedAt'],
        // ignoredPaths: [
        //   'chat.chats.createdAt',
        //   'chat.chats.updatedAt',
        //   'chat.messages.createdAt',
        //   'chat.messages.updatedAt',
        //   'model.models.lastUpdated'
        // ],
      },
    //}).concat(logger),
    })
});

injectStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;