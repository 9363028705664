import React from 'react';
import { MathJaxContext } from 'better-react-mathjax';

const config = {
  loader: { load: ['[tex]/mhchem'] },
  tex: {
    inlineMath: [['$', '$'], ['\\(', '\\)']],
    displayMath: [['$$', '$$'], ['\\[', '\\]']],
    packages: {
      '[+]': ['mhchem', 'ams', 'noerrors', 'noundefined']
    },
    macros: {},
    tags: 'ams',
    tagSide: 'right',
    processEscapes: true,
  },
  startup: {
    typeset: true,
    ready: () => {
    },
  },
  options: {
    enableMenu: false,
    processEnvironments: true,
    processEscapes: true,
    skipHtmlTags: ['noscript', 'style', 'textarea', 'pre', 'code'],
    processRefs: true,
  },
};

const MathJaxContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <MathJaxContext version={3} config={config}>
      {children}
    </MathJaxContext>
  );
};

export default MathJaxContextProvider;