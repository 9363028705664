import { axiosInstance } from './axiosConfig';
import { Folder } from '../types/types';


/**
 * Fetches all folders for the current user
 * @returns Array of folders
 * @throws {ServerError} For unexpected errors
 */
export const getAllFoldersAPI = async (): Promise<Folder[]> => {
  try {
    const response = await axiosInstance.get(`/folders`);
    return response.data;
  } catch (error) {
    throw error; // Let interceptor handle error mapping
  }
};

/**
 * Creates a new folder
 * @param name - The name for the new folder
 * @param parentFolderId - Optional ID of parent folder
 * @returns The newly created folder
 * @throws {ValidationError} If folder data is invalid
 * @throws {NotFoundError} If parent folder doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const createFolderAPI = async (name: string, parentFolderId?: string): Promise<Folder> => {
  try {
    const response = await axiosInstance.post(`/folders`, { name, parentFolderId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Renames an existing folder
 * @param folderId - The ID of the folder to rename
 * @param name - The new name for the folder
 * @returns The updated folder
 * @throws {ValidationError} If name is invalid
 * @throws {NotFoundError} If folder doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const renameFolderAPI = async (folderId: string, name: string): Promise<Folder> => {
  try {
    const response = await axiosInstance.put(`/folders/${folderId}/rename`, { name });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a folder
 * @param folderId - The ID of the folder to delete
 * @throws {NotFoundError} If folder doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const deleteFolderAPI = async (folderId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/folders/${folderId}`);
  } catch (error) {
    throw error;
  }
};

/**
 * Moves a folder to a new parent folder
 * @param folderId - The ID of the folder to move
 * @param newParentFolderId - Optional ID of new parent folder
 * @returns The updated folder
 * @throws {NotFoundError} If folder or parent folder doesn't exist
 * @throws {ValidationError} If move would create circular reference
 * @throws {ServerError} For unexpected errors
 */
export const moveFolderAPI = async (folderId: string, newParentFolderId?: string): Promise<Folder> => {
  try {
    const response = await axiosInstance.put(`/folders/${folderId}/move`, { newParentFolderId });
    return response.data;
  } catch (error) {
    throw error;
  }
};