import React, { useState, useEffect } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton, HiddenSpan, PasswordVisibilityButton } from '../common/StyledAuthComponents';
import { Box, CircularProgress, Fade } from '@mui/material';
import { supabase } from '../../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../../store/authSlice';
import { AppDispatch } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import SuccessNotification from '../common/SuccessNotification';
import ErrorNotification from '../common/ErrorNotification';
import PasswordStrengthIndicator from '../common/PasswordStrengthIndicator';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';

interface ResetPasswordFormProps {
  onSwitchToLogin: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        setErrorMsg('Invalid or expired reset link. Please request a new password reset.');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    };
    
    checkSession();
  }, [navigate]);

  const validateForm = (): boolean => {
    const errors: typeof validationErrors = {};
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (!passwordRegex.test(formData.password)) {
      errors.password = 'Password must be at least 6 characters with one letter and one number';
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (field: keyof typeof formData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }));
    setErrorMsg('');
    setSuccessMsg('');
    setValidationErrors({});
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setValidationErrors({});
    setErrorMsg('');
    setSuccessMsg('');

    if (!validateForm()) return;

    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({ 
        password: formData.password 
      });

      if (error) {
        dispatch(setError(error.message));
        setErrorMsg(error.message);
      } else {
        setSuccessMsg('Password has been reset successfully! Redirecting to login...');
        setTimeout(() => {
          onSwitchToLogin();
        }, 2000);
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setErrorMsg('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleResetPassword}
      sx={(theme) => ({
        position: 'relative',
        transition: theme.animation.transition.medium,
      })}
    >
      <AuthTitle>Set New Password</AuthTitle>

      <Fade in={!!errorMsg} timeout={300}>
        <Box>{errorMsg && <ErrorNotification message={errorMsg} />}</Box>
      </Fade>

      <Fade in={!!successMsg} timeout={300}>
        <Box>{successMsg && <SuccessNotification message={successMsg} />}</Box>
      </Fade>

      {!successMsg && (
        <Box
          sx={(theme) => ({
            position: 'relative',
            opacity: loading ? 0.7 : 1,
            pointerEvents: loading ? 'none' : 'auto',
            transition: theme.animation.transition.medium,
          })}
        >
          <Box sx={{ mb: 2, color: 'text.secondary' }}>
            Please enter your new password below.
          </Box>

          <AuthTextField
            label="New Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            required
            value={formData.password}
            onChange={handleInputChange('password')}
            error={!!validationErrors.password}
            helperText={validationErrors.password}
            disabled={loading}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <PasswordVisibilityButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  disabled={loading}
                  tabIndex={-1}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </PasswordVisibilityButton>
              ),
              sx: (theme) => ({
                transition: theme.animation.transition.medium,
              }),
            }}
          />
          <PasswordStrengthIndicator password={formData.password} />

          <AuthTextField
            label="Confirm New Password"
            variant="outlined"
            type="password"
            required
            value={formData.confirmPassword}
            onChange={handleInputChange('confirmPassword')}
            error={!!validationErrors.confirmPassword}
            helperText={validationErrors.confirmPassword}
            disabled={loading}
            autoComplete="new-password"
            InputProps={{
              sx: (theme) => ({
                transition: theme.animation.transition.medium,
              }),
            }}
          />

          <AuthButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={(theme) => ({
              position: 'relative',
              '& .MuiCircularProgress-root': {
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px',
              },
            })}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                <HiddenSpan>Reset Password</HiddenSpan>
              </>
            ) : (
              'Reset Password'
            )}
          </AuthButton>

          <AuthLink>
            <AuthLinkButton onClick={onSwitchToLogin}>
              Back to Login
            </AuthLinkButton>
          </AuthLink>
        </Box>
      )}
    </Box>
  );
};

export default ResetPasswordForm;