import { ThemeOptions, createTheme } from '@mui/material/styles';
import { generateThemeModelColors } from './modelColors';
import { useAppSelector } from '../hooks/reduxHooks';
import { useMemo } from 'react';
import { selectModelIds } from '../store/modelSlice';

declare module '@mui/material/styles' {
  interface Theme {
    modelColors: {
      [key: string]: string;
    };
    illustrations: {
      borderRadius: number;
      overlay: {
        primary: string;
        secondary: string;
      };
      style: {
        flatDesign: boolean;
        abstractShapes: boolean;
      };
      animation: {
        duration: number;
        easing: string;
      };
    };
    animation: {
      microInteraction: {
        duration: string;
        easing: string;
      };
      transition: {
        short: string;
        medium: string;
        long: string;
      };
      hover: {
        scale: string;
        translateY: string;
      };
    };
    glassmorphism: {
      light: {
        background: string;
        border: string;
        shadow: string;
        blur: string;
      };
      dark: {
        background: string;
        border: string;
        shadow: string;
        blur: string;
      };
    };
  }
  interface ThemeOptions {
    modelColors?: {
      [key: string]: string;
    };
    illustrations?: {
      borderRadius: number;
      overlay: {
        primary: string;
        secondary: string;
      };
      style: {
        flatDesign: boolean;
        abstractShapes: boolean;
      };
      animation: {
        duration: number;
        easing: string;
      };
    };
    animation?: {
      microInteraction?: {
        duration: string;
        easing: string;
      };
      transition?: {
        short: string;
        medium: string;
        long: string;
      };
      hover?: {
        scale: string;
        translateY: string;
      };
    };
    glassmorphism?: {
      light: {
        background: string;
        border: string;
        shadow: string;
        blur: string;
      };
      dark: {
        background: string;
        border: string;
        shadow: string;
        blur: string;
      };
    };
  }
  interface PaletteColor {
    50?: string;
    900?: string;
  }
}

// Spacing constants as per style guide
export const SPACING = {
  XS: 4,
  S: 8,
  M: 16,
  L: 24,
  XL: 32,
  XXL: 40,
} as const;

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00A0E0',      // Celestial blue - Main
      light: '#50C7F6',     // Light
      dark: '#045F84',      // Dark
      50: '#E6F8FF',      // Very light
      900: '#002533',     // Very dark
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#D92626',      // Rojo - Main
      light: '#D95959',     // Light
      dark: '#A61E1E',      // Dark
      50: '#FFCCCC',      // Very light
      900: '#300909',     // Very dark
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#E0002D',
      light: '#F34165',
      dark: '#C30000',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFA000',
      light: '#FFC947',
      dark: '#C67100',
      contrastText: '#000000',
    },
    info: {
      main: '#2196F3',
      light: '#64B5F6',
      dark: '#0B79D0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      light: '#80E27E',
      dark: '#087F23',
      contrastText: '#FFFFFF',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#303030',
      A700: '#616161',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
      disabled: '#9E9E9E',
    },
    divider: '#E0E0E0',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.167,
      letterSpacing: '0',
    },
    h4: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.334,
      letterSpacing: '0',
    },
    h6: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: (factor: number) => `${8 * factor}px`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  illustrations: {
    borderRadius: 12,
    overlay: {
      primary: 'rgba(0, 160, 224, 0.1)',
      secondary: 'rgba(224, 0, 75, 0.1)',
    },
    style: {
      flatDesign: true,
      abstractShapes: true,
    },
    animation: {
      duration: 300,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
  },
  animation: {
    microInteraction: {
      duration: '0.2s',
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    transition: {
      short: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
      medium: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
      long: '0.5s all cubic-bezier(0.4, 0, 0.2, 1)',
    },
    hover: {
      scale: 'scale(1.02)',
      translateY: 'translateY(-2px)',
    },
  },
  glassmorphism: {
    light: {
      background: 'rgba(255, 255, 255, 0.8)',
      border: '1px solid rgba(255, 255, 255, 0.18)',
      shadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
      blur: 'blur(10px)',
    },
    dark: {
      background: 'rgba(17, 25, 40, 0.75)',
      border: '1px solid rgba(255, 255, 255, 0.125)',
      shadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
      blur: 'blur(10px)',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        role: 'button',
        tabIndex: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          padding: `${SPACING.S}px ${SPACING.M}px`,
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-1px)',
          },
          '&:active': {
            transform: 'scale(0.98)',
          },
          '&.loading': {
            '&::after': {
              content: '""',
              position: 'absolute',
              animation: 'button-loading 1s infinite',
            },
          },
        },
        sizeLarge: {
          padding: `${SPACING.M}px ${SPACING.L}px`,
        },
        sizeSmall: {
          padding: `${SPACING.XS}px ${SPACING.S}px`,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        role: 'button',
        tabIndex: 0,
        'aria-label': 'icon button',
      },
      styleOverrides: {
        root: {
          padding: SPACING.XS,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
            transform: 'scale(1.1)',
          },
          '&:active': {
            transform: 'scale(0.95)',
          },
        },
        sizeLarge: {
          padding: SPACING.S,
        },
        sizeSmall: {
          padding: SPACING.XS,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
          '&.icon-small': {
            fontSize: 16,
          },
          '&.icon-large': {
            fontSize: 32,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(8px)',
            borderColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              transform: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused': {
              transform: 'none',
              boxShadow: '0 4px 20px rgba(0, 160, 224, 0.15)',
            },
            '&.Mui-error': {
              backgroundColor: 'rgba(224, 0, 45, 0.05)',
            },
          },
          '& .MuiInputLabel-root': {
            transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
            '&.Mui-focused': {
              color: '#00A0E0',
            },
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
            transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid rgba(255, 255, 255, 0.18)',
          boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 12px 40px 0 rgba(31, 38, 135, 0.25)',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '16px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          border: '1px solid rgba(255, 255, 255, 0.18)',
          padding: SPACING.M,
          '& .MuiDialogTitle-root': {
            padding: `${SPACING.M}px ${SPACING.M}px ${SPACING.S}px`,
          },
          '& .MuiDialogContent-root': {
            padding: `${SPACING.S}px ${SPACING.M}px`,
          },
          '& .MuiDialogActions-root': {
            padding: `${SPACING.S}px ${SPACING.M}px ${SPACING.M}px`,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
        elevation1: {
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: SPACING.S,
        },
        switchBase: {
          padding: 1,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#00A0E0',
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 160, 224, 0.1)',
          '&::after': {
            background: 'linear-gradient(90deg, transparent, rgba(0, 160, 224, 0.05), transparent)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backdropFilter: 'blur(8px)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          '&.MuiAlert-standardSuccess': {
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
            border: '1px solid rgba(76, 175, 80, 0.2)',
          },
          '&.MuiAlert-standardError': {
            backgroundColor: 'rgba(224, 0, 45, 0.1)',
            border: '1px solid rgba(224, 0, 45, 0.2)',
          },
          '&.MuiAlert-standardWarning': {
            backgroundColor: 'rgba(255, 160, 0, 0.1)',
            border: '1px solid rgba(255, 160, 0, 0.2)',
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: 'rgba(33, 150, 243, 0.1)',
            border: '1px solid rgba(33, 150, 243, 0.2)',
          },
        },
        icon: {
          opacity: 0.9,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          textDecoration: 'none',
          color: '#00A0E0',
          '&:hover': {
            color: '#045F84',
            textDecoration: 'none',
            transform: 'translateY(-1px)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#00A0E0',
              borderWidth: '2px',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: SPACING.S,
          '&.Mui-error': {
            color: '#E0002D',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 32, 51, 0.9)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          padding: `${SPACING.S}px ${SPACING.M}px`,
          fontSize: '0.875rem',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.15)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
        arrow: {
          color: 'rgba(0, 32, 51, 0.9)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 160, 224, 0.12)',
          '&.MuiDivider-vertical': {
            marginRight: SPACING.M,
            marginLeft: SPACING.M,
          },
          '&.MuiDivider-middle': {
            marginTop: SPACING.M,
            marginBottom: SPACING.M,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-checked': {
            '&:hover': {
              backgroundColor: 'rgba(0, 160, 224, 0.12)',
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-checked': {
            '&:hover': {
              backgroundColor: 'rgba(0, 160, 224, 0.12)',
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 37, 51, 0.5)',
          backdropFilter: 'blur(8px)',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 160, 224, 0.08)',
        },
        bar: {
          borderRadius: 4,
          backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)',
          backgroundSize: '40px 40px',
          animation: 'progress-bar-stripes 1s linear infinite',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        },
        colorDefault: {
          backgroundColor: 'rgba(0, 160, 224, 0.12)',
          color: '#00A0E0',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 160, 224, 0.12)',
        },
        indicator: {
          height: 3,
          borderRadius: '3px 3px 0 0',
          backgroundColor: '#00A0E0',
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            color: '#00A0E0',
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-selected': {
            color: '#00A0E0',
            fontWeight: 600,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(0, 160, 224, 0.12)',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: SPACING.M,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-expanded': {
            backgroundColor: 'rgba(0, 160, 224, 0.05)',
          },
        },
        expandIconWrapper: {
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(0, 160, 224, 0.12)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        },
        list: {
          padding: `${SPACING.XS}px 0`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 160, 224, 0.12)',
            '&:hover': {
              backgroundColor: 'rgba(0, 160, 224, 0.16)',
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 160, 224, 0.12)',
            '&:hover': {
              backgroundColor: 'rgba(0, 160, 224, 0.16)',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 40,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          transition: '0.2s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.08)',
            transform: 'translateX(4px)',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: SPACING.M,
          '@media (min-width: 600px)': {
            padding: SPACING.L,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& > .MuiGrid-item': {
            padding: SPACING.M,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
        outlined: {
          transition: '0.3s all cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -11,
          '& .MuiFormControlLabel-label': {
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#00A0E0',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(0, 160, 224, 0.12)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            fontWeight: 600,
            backgroundColor: 'rgba(0, 160, 224, 0.05)',
          },
          '& .MuiTableCell-root': {
            borderBottom: '1px solid rgba(0, 160, 224, 0.12)',
          },
          '& .MuiTableRow-root:hover': {
            backgroundColor: 'rgba(0, 160, 224, 0.04)',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          border: '1px solid rgba(0, 160, 224, 0.12)',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: `${SPACING.XS}px 0`,
        },
        primary: {
          fontWeight: 500,
        },
        secondary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          fontWeight: 600,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: SPACING.M,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: SPACING.M,
        },
      },
    },
  },
};

export const useTheme = () => {
  const models = useAppSelector(selectModelIds);
  
  const themes = useMemo(() => {
    const lightTheme = createTheme({
      ...lightThemeOptions,
      modelColors: generateThemeModelColors(models, 'light')
    });

    return { lightTheme };
  }, [models]);

  return themes;
};