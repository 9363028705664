import React from 'react';
import { Box } from '@mui/material';
import { styled } from 'styled-components';

const TransitionContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  overflow: 'hidden',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '& > *': {
    width: '100%',
    animation: 'fadeIn 150ms ease-out forwards',
    willChange: 'transform, opacity',
  },
}));

interface SettingsTransitionProps {
  children: React.ReactNode;
}

const SettingsTransition: React.FC<SettingsTransitionProps> = ({ children }) => {
  return <TransitionContainer>{children}</TransitionContainer>;
};

export default React.memo(SettingsTransition);