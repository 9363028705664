import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton, HiddenSpan } from '../common/StyledAuthComponents';
import { Box, CircularProgress, Fade } from '@mui/material';
import { supabase } from '../../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../../store/authSlice';
import { AppDispatch } from '../../../store/store';
import ErrorNotification from '../common/ErrorNotification';
import { initiateRegistration, registrationCheck } from '../../../api/auth';
import validator from 'validator';
import OAuthButtons from '../common/OAuthButtons';
import { PasswordVisibilityButton } from '../common/StyledAuthComponents';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordStrengthIndicator from '../common/PasswordStrengthIndicator';


interface RegistrationFormProps {
  onSwitchToLogin: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [validationErrors, setValidationErrors] = useState<{
    email?: string;
    password?: string;
    confirmPassword?: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const sanitizeEmail = (email: string) => email.toLowerCase().trim();

  const validateForm = (): boolean => {
    const errors: typeof validationErrors = {};
    const emailValid = validator.isEmail(sanitizeEmail(formData.email), { allow_underscores: true });

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!emailValid) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    } else {
      // Password strength: min 6 chars, at least 1 letter and 1 digit
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(formData.password)) {
        errors.password = 'Password must be at least 6 characters with one letter and one number';
      }
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (field: keyof typeof formData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }));
    setErrorMsg('');
    setSuccessMsg('');
    setValidationErrors({});
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setErrorMsg('');
    setSuccessMsg('');
    setValidationErrors({});

    if (!validateForm()) return;

    setLoading(true);

    try {
      const { exists } = await registrationCheck({ 
        email: sanitizeEmail(formData.email) 
      });

      if (exists) {
        setErrorMsg('Account already exists! Please use the login page.');
        return;
      }

      const { data, error } = await supabase.auth.signUp({
        email: sanitizeEmail(formData.email),
        password: formData.password,
      });

      if (error) {
        dispatch(setError(error.message));
        setErrorMsg(error.message);
        return;
      }

      if (data.user) {
        const registrationResponse = await initiateRegistration({ user: data.user });
        if (registrationResponse) {
          setSuccessMsg('Registration successful! Please check your email for verification.');
        } else {
          setErrorMsg('An error occurred during registration. Please try again.');
        }
      }
    } catch (error) {
      console.error('Registration error:', error);
      setErrorMsg('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleRegister}
      sx={(theme) => ({
        position: 'relative',
        transition: theme.animation.transition.medium,
      })}
    >
      <AuthTitle>Create Account</AuthTitle>

      <Fade in={!!errorMsg} timeout={300}>
        <Box>{errorMsg && <ErrorNotification message={errorMsg} />}</Box>
      </Fade>

      {!successMsg ? (
        <>
          <OAuthButtons />

          <Box
            sx={(theme) => ({
              position: 'relative',
              opacity: loading ? 0.7 : 1,
              pointerEvents: loading ? 'none' : 'auto',
              transition: theme.animation.transition.medium,
            })}
          >
            <AuthTextField
              label="Email"
              variant="outlined"
              type="email"
              required
              value={formData.email}
              onChange={handleInputChange('email')}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              disabled={loading}
              autoComplete="email"
              InputProps={{
                sx: (theme) => ({
                  transition: theme.animation.transition.medium,
                }),
              }}
            />

            <AuthTextField
              label="Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              required
              value={formData.password}
              onChange={handleInputChange('password')}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
              disabled={loading}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    disabled={loading}
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </PasswordVisibilityButton>
                ),
                sx: (theme) => ({
                  transition: theme.animation.transition.medium,
                }),
              }}
            />
            <PasswordStrengthIndicator password={formData.password} />

            <AuthTextField
              label="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? 'text' : 'password'}
              required
              value={formData.confirmPassword}
              onChange={handleInputChange('confirmPassword')}
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
              disabled={loading}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                    disabled={loading}
                    tabIndex={-1}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </PasswordVisibilityButton>
                ),
                sx: (theme) => ({
                  transition: theme.animation.transition.medium,
                }),
              }}
            />

            <AuthButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={(theme) => ({
                position: 'relative',
                '& .MuiCircularProgress-root': {
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-12px',
                },
              })}
            >
              {loading ? (
                <>
                  <CircularProgress size={24} color="inherit" />
                  <HiddenSpan>Register</HiddenSpan>
                </>
              ) : (
                'Register'
              )}
            </AuthButton>

            <AuthLink>
              Already have an account?{' '}
              <AuthLinkButton onClick={onSwitchToLogin}>
                Login
              </AuthLinkButton>
            </AuthLink>
          </Box>
        </>
      ) : (
        <Fade in timeout={300}>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Box sx={{ mb: 3 }}>{successMsg}</Box>
            <AuthButton
              variant="outlined"
              color="primary"
              onClick={onSwitchToLogin}
            >
              Go to Login
            </AuthButton>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default RegistrationForm;
