import { ErrorCode } from './errorCodes';

export interface ApiErrorResponse {
  status: number;
  error: string;
  message: string;
  errorCode: ErrorCode;
  details?: any;
}

export enum ErrorSeverity {
  LOW = 'LOW',       // Toast notifications
  MEDIUM = 'MEDIUM', // In-line error messages
  HIGH = 'HIGH'      // Modal dialogs
}

export interface ErrorConfig {
  severity: ErrorSeverity;
  retryable: boolean;
  userActionRequired: boolean;
  clearanceAction?: 'RETRY' | 'REDIRECT' | 'REFRESH' | 'CONTACT_SUPPORT';
}

export interface ErrorMapping {
  [ErrorCode.VALIDATION_ERROR]: ErrorConfig;
  [ErrorCode.AUTHENTICATION_ERROR]: ErrorConfig;
  [ErrorCode.NOT_FOUND_ERROR]: ErrorConfig;
  [ErrorCode.EXTERNAL_SERVICE_ERROR]: ErrorConfig;
  [ErrorCode.RATE_LIMIT_ERROR]: ErrorConfig;
  [ErrorCode.SERVER_ERROR]: ErrorConfig;
  [ErrorCode.CREDIT_INSUFFICIENT]: ErrorConfig;
  [ErrorCode.CREDIT_PARTIALLY_SUFFICIENT]: ErrorConfig;
  [ErrorCode.INVALID_MODEL]: ErrorConfig;
  [ErrorCode.CHAT_PERMISSION_ERROR]: ErrorConfig;
  [ErrorCode.MESSAGE_TOO_LONG]: ErrorConfig;
  [ErrorCode.MODERATED_CONTENT_ERROR]: ErrorConfig;
  [ErrorCode.SERVICE_UNAVAILABLE_ERROR]: ErrorConfig;
  [ErrorCode.TIMEOUT_ERROR]: ErrorConfig;
  [ErrorCode.OPENROUTER_ACCOUNT_ERROR]: ErrorConfig;
  [ErrorCode.OPENROUTER_RATE_LIMIT_ERROR]: ErrorConfig;
  [ErrorCode.OPENROUTER_TIMEOUT_ERROR]: ErrorConfig;
  [ErrorCode.OPENROUTER_API_ERROR]: ErrorConfig;
  [ErrorCode.OPENROUTER_UPSTREAM_ERROR]: ErrorConfig;
  [ErrorCode.TITLE_GENERATION_ERROR]: ErrorConfig;
  [ErrorCode.GENERATION_DETAILS_ERROR]: ErrorConfig;
  [ErrorCode.CONTEXT_LIMIT_EXCEEDED]: ErrorConfig;
}

export class AppError extends Error {
  public readonly status: number;
  public readonly errorCode: ErrorCode;
  public readonly severity: ErrorSeverity;
  public readonly retryable: boolean;
  public readonly userActionRequired: boolean;
  public readonly clearanceAction?: string;

  constructor(apiError: ApiErrorResponse, config: ErrorConfig) {
    super(apiError.message);
    this.name = 'AppError';
    this.status = apiError.status;
    this.errorCode = apiError.errorCode;
    this.severity = config.severity;
    this.retryable = config.retryable;
    this.userActionRequired = config.userActionRequired;
    this.clearanceAction = config.clearanceAction;
  }
}