import { axiosInstance, skipAuthConfig } from './axiosConfig';
import { logoutDirect, clearAuthState } from '../store/authSlice';
import { disconnectSocket } from '../services/socketService';
import { clearChatState } from '../store/chatSlice';
import { clearModelState } from '../store/modelSlice';
import { clearUIState } from '../store/uiSlice';
import { getStore } from '../utils/storeInjector';
import { User as SupabaseUser, Session as SupabaseSession } from '@supabase/supabase-js';
import { MappedError } from '../errors/errorUtils';

/**
 * Checks if an email is already registered
 * @throws {ValidationError} If email is missing
 * @throws {ServerError} For unexpected errors
 */
export const registrationCheck = async (data: { email: string }) => {
  try {
    const response = await axiosInstance.post('/auth/registration-check', data, skipAuthConfig);
    return response.data;
  } catch (error) {
    throw error; // Let interceptor handle the error mapping
  }
};

/**
 * Updates the first login status for a user
 * @throws {AuthenticationError} If not authenticated
 * @throws {ServerError} For unexpected errors
 */
export const setIsFirstLogin = async (data: { isFirstLogin: boolean }) => {
  try {
    const response = await axiosInstance.post('/auth/set-is-first-login', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Initiates the registration process for a new user
 * @throws {ValidationError} If user data is invalid
 * @throws {ServerError} For unexpected errors
 */
export const initiateRegistration = async (data: { user: SupabaseUser }) => {
  try {
    const response = await axiosInstance.post('/auth/registration-start', data, skipAuthConfig);
    return response.status === 200;
  } catch (error) {
    throw error;
  }
};

/**
 * Logs out the user from current or all sessions
 * @throws {AuthenticationError} If session is invalid
 * @throws {ServerError} For unexpected errors
 */
export const logout = async ({ cleanupOnly = false }) => {
  try {
    if (!cleanupOnly) {
      const response = await getStore().dispatch(logoutDirect()).unwrap();
      if (response !== true) {
        throw new Error('Supabase logout failed. Please try again.');
      }
      return response;
    }

    disconnectSocket();

    // Clear all Redux states
    await getStore().dispatch(clearAuthState());
    await getStore().dispatch(clearChatState());
    await getStore().dispatch(clearModelState());
    await getStore().dispatch(clearUIState());

    // Clear storage
    localStorage.removeItem('lastActiveChat');
    localStorage.removeItem('lastOpenedChatId');
    localStorage.removeItem('lastModelConfigUpdateTime');
    localStorage.removeItem('userSettings');
    localStorage.removeItem('oauth_provider_token');
    localStorage.removeItem('oauth_provider_refresh_token');

    sessionStorage.clear();
  } catch (error) {
    throw error;
  }
};

/**
 * Logs out user from all active sessions
 * @throws {AuthenticationError} If not authenticated
 * @throws {ServerError} For unexpected errors
 */
export const logoutAllSessions = async () => {
  try {
    const response = await axiosInstance.post('/auth/logout-all');
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Checks if the current session is active
 * @throws {AuthenticationError} If session is invalid
 * @throws {ServerError} For unexpected errors
 */
export const checkSessionStatus = async () => {
  try {
    const response = await axiosInstance.get('/auth/check-session');
    return response.data.isActive;
  } catch (error) {
    if ((error as MappedError).errorCode) {
      return false; // Session check failures should return false rather than throw
    }
    throw error;
  }
};

/**
 * Refreshes the authentication token on the server
 * @throws {ValidationError} If session data is missing
 * @throws {NotFoundError} If session not found
 * @throws {ServerError} For unexpected errors
 */
export const refreshTokenOnServer = async () => {
  try {
    const response = await axiosInstance.post('/auth/refresh-token', {
      session: getStore().getState().auth.session,
      sessionId: getStore().getState().auth.sessionId
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Logs in a user
 * @throws {ValidationError} If credentials are invalid
 * @throws {AuthenticationError} If authentication fails
 * @throws {ServerError} For unexpected errors
 */
export const login = async (credentials: { session: SupabaseSession }) => {
  try {
    const response = await axiosInstance.post('/auth/login', credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};