import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PasswordStrengthIndicatorProps {
  password: string;
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const getPasswordStrength = (password: string): {
  score: number;
  label: string;
  color: string;
} => {
  if (!password) {
    return { score: 0, label: '', color: 'grey.500' };
  }

  let score = 0;
  let checks = 0;

  // Length check
  if (password.length >= 8) {
    checks++;
  }

  // Contains number
  if (/\d/.test(password)) {
    checks++;
  }

  // Contains lowercase
  if (/[a-z]/.test(password)) {
    checks++;
  }

  // Contains uppercase
  if (/[A-Z]/.test(password)) {
    checks++;
  }

  // Contains special character
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    checks++;
  }

  score = (checks / 5) * 100;

  if (score === 0) return { score, label: '', color: 'grey.500' };
  if (score <= 20) return { score, label: 'Very Weak', color: '#ff1744' };
  if (score <= 40) return { score, label: 'Weak', color: '#ff9100' };
  if (score <= 60) return { score, label: 'Fair', color: '#ffd600' };
  if (score <= 80) return { score, label: 'Good', color: '#00e676' };
  return { score, label: 'Strong', color: '#00c853' };
};

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
  const strength = getPasswordStrength(password);

  if (!password) {
    return null;
  }

  return (
    <Box sx={{ mt: 0.5, mb: 1 }}>
      <StyledLinearProgress
        variant="determinate"
        value={strength.score}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: strength.color,
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{
          color: strength.color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 0.5,
        }}
      >
        {strength.label}
      </Typography>
    </Box>
  );
};

export default PasswordStrengthIndicator; 