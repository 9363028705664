import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  position: 'relative',
  overflow: 'hidden',
  
  // Dynamic gradient background
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(135deg, 
      ${theme.palette.primary.light} 0%, 
      ${theme.palette.secondary[50]} 100%
    )`,
    opacity: 0.9,
    zIndex: 0,
  }
}));

export const AuthCard = styled(Box)(({ theme }) => ({
  // Glassmorphic effect from theme
  background: theme.glassmorphism.light.background,
  backdropFilter: theme.glassmorphism.light.blur,
  border: theme.glassmorphism.light.border,
  boxShadow: theme.glassmorphism.light.shadow,
  
  borderRadius: theme.shape.borderRadius * 1.5, // 12px based on theme
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '400px',
  position: 'relative',
  zIndex: 1,
  
  // Animation from theme
  transition: theme.animation.transition.medium,

}));

export const AuthTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightBold,
  position: 'relative',
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: theme.spacing(-1),
    left: '50%',
    transform: 'translateX(-50%)',
    width: '40px',
    height: '3px',
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius / 4,
  }
}));

export const AuthTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    transition: theme.animation.transition.medium,
    
    '& fieldset': {
      borderColor: `${theme.palette.primary.main}33`, // 20% opacity
      transition: theme.animation.transition.short,
    },
    
    '&:hover fieldset': {
      borderColor: theme.palette.primary.light,
    },
    
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      }
    }
  },
  
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  },
  
  '& .MuiInputBase-input': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
  },
  
  // Error state
  '& .Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    }
  },
  
  '& .MuiFormHelperText-root': {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  }
}));

export const AuthButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: theme.typography.button.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'none',
  fontFamily: theme.typography.fontFamily,
  
  // Animation
  transition: theme.animation.transition.short,
  position: 'relative',
  overflow: 'hidden',
  
  '&:hover': {
    transform: theme.animation.hover.translateY,
    boxShadow: `0 4px 12px ${theme.palette.primary.main}33`,
  },
  
  '&:active': {
    transform: 'translateY(1px)',
  },
  
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  }
}));

export const AuthLink = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
  fontFamily: theme.typography.fontFamily,
}));

export const AuthLinkButton = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  padding: theme.spacing(0.5),
  fontSize: 'inherit',
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  transition: theme.animation.transition.short,
  
  '&:hover': {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
  
  '&:focus': {
    outline: 'none',
    textDecoration: 'underline',
  },
  
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: '2px',
    borderRadius: theme.shape.borderRadius / 2,
  }
}));

export const HiddenSpan = styled('span')({
  visibility: 'hidden'
});

export const PasswordVisibilityButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(0.5),
  
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  
  '&.Mui-disabled': {
    opacity: 0.5,
  },

  // Handle Chrome/Safari autofill
  'input:-webkit-autofill + &': {
    backgroundColor: 'rgb(232, 240, 254)',
    transition: 'background-color 0s 50000s',
  },

  // Handle Firefox autofill
  'input:-moz-autofill + &': {
    backgroundColor: 'rgb(250, 255, 189)',
  },

  // Handle Edge/IE autofill
  'input:-ms-autofill + &': {
    backgroundColor: 'rgb(232, 240, 254)',
  },

  // Generic autofill handling (future-proof)
  'input:autofill + &': {
    backgroundColor: 'rgb(232, 240, 254)',
  },

  transition: theme.animation.transition.medium,
}));