import { AppDispatch } from '../store/store';
import { Message } from '../types/types';
import { abortStreamingMessage, updateChatCreditWarning, updateMessageAsFailed, updateMessageId } from '../store/chatSlice';
import { MappedError } from './errorUtils';
import { ErrorCode } from './errorCodes';
import { setCreditInsufficient, setCreditPartiallyInsufficient } from '../store/uiSlice';

export const handleSendError = (
  error: MappedError,
  selectedChatId: string,
  dispatch: AppDispatch,
  userMessage?: Message,
  aiMessage?: Message
) => {
  console.error('Error in handleSend:', error);

  const errorDetails = error.details;

  if (errorDetails.messageProcessing.userMessageId && userMessage) {
    dispatch(updateMessageId({ chatId: selectedChatId, message: userMessage, newMessageId: errorDetails.messageProcessing.userMessageId }));
  }

  if((!errorDetails.responseProcessing.aiMessageId) && aiMessage) {
    dispatch(abortStreamingMessage({ chatId: selectedChatId }));
  }

  switch (error.errorCode) {
    case ErrorCode.CREDIT_INSUFFICIENT:
      dispatch(setCreditInsufficient(true));
      break;
    case ErrorCode.CREDIT_PARTIALLY_SUFFICIENT:
      dispatch(updateChatCreditWarning({ chatId: selectedChatId, creditWarning: false }));
      dispatch(setCreditPartiallyInsufficient(true));
      break;
  }

  if(userMessage) {
    dispatch(updateMessageAsFailed({ chatId: selectedChatId, messageId: userMessage._id }));
  }
};