import { alpha } from '@mui/material/styles';
import { styled } from 'styled-components';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, TableContainer, Pagination, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';

export const SettingsDialog = styled(Dialog)(({ theme }) => ({
  '@keyframes settingsDialogIn': {
    '0%': {
      opacity: 0,
      transform: 'translate(-50%, -45%) scale(0.95)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
  '@keyframes settingsDialogOut': {
    '0%': {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate(-50%, -45%) scale(0.95)',
    },
  },
  '& .MuiDialog-paper': {
    width: '90%',
    height: '90%',
    maxWidth: 1200,
    maxHeight: 800,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    margin: 0,
    animation: 'settingsDialogIn 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '&.MuiDialog-paper-exiting': {
      animation: 'settingsDialogOut 200ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: 'none',
      borderRadius: 0,
      animation: 'none',
      transform: 'none',
      top: 0,
      left: 0,
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: alpha(theme.palette.background.default, 0.75),
    backdropFilter: 'blur(4px)',
    transition: theme.transitions.create(['opacity', 'backdrop-filter'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export const SettingsDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  padding: 0,
  height: 'calc(100% - 40px)',
  background: theme.palette.background.default,
  overflow: 'hidden',
}));

export const SettingsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const SettingsContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  overflowY: 'auto',
  background: alpha(theme.palette.background.paper, 0.5),
  position: 'relative',
  height: '100%',
  backdropFilter: 'blur(8px)',
  transition: theme.transitions.create(['padding', 'background'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    display: 'block',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.text.secondary, 0.05),
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.text.secondary, 0.2),
    borderRadius: '4px',
    '&:hover': {
      background: alpha(theme.palette.text.secondary, 0.3),
    },
  },
  scrollbarWidth: 'thin',
  scrollbarColor: `${alpha(theme.palette.text.secondary, 0.2)} ${alpha(theme.palette.text.secondary, 0.05)}`,
}));

// Updated SettingsSidebar
export const SettingsSidebar = styled(Box)(({ theme }) => ({
  width: 280,
  borderRight: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper,
  overflowY: 'auto',
  //overflowX: 'hidden',
  padding: theme.spacing(3, 2),
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    display: 'block',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.text.secondary, 0.05),
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.text.secondary, 0.2),
    borderRadius: '4px',
    '&:hover': {
      background: alpha(theme.palette.text.secondary, 0.3),
    },
  },
  scrollbarWidth: 'thin',
  scrollbarColor: `${alpha(theme.palette.text.secondary, 0.2)} ${alpha(theme.palette.text.secondary, 0.05)}`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2),
  },
}));

export const SettingsSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  position: 'relative',
  '&:not(:last-child)': {
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      right: theme.spacing(2),
      height: '1px',
      background: `linear-gradient(to right, 
        ${alpha(theme.palette.divider, 0)}, 
        ${alpha(theme.palette.divider, 0.7)}, 
        ${alpha(theme.palette.divider, 0)}
      )`,
    }
  },
  '@media (hover: hover)': {
    '&:hover': {
      '& .MuiListItem-root:not(:hover)': {
        opacity: 0.7,
        transform: 'scale(0.98)',
      },
    },
  },
}));

export const SettingsSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  letterSpacing: '0.1em',
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

export const SettingsList = styled(List)({
  padding: 0,
});

export const SettingsListItem = styled(ListItem)<{ selected?: boolean }>(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(1.5, 2),
  cursor: 'pointer',
  transform: 'translateX(0)',
  transition: theme.transitions.create(
    ['background-color', 'box-shadow', 'opacity', 'transform'],
    { duration: theme.transitions.duration.shorter }
  ),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    transform: 'translateX(4px) scale(1.01)',
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
  },
  ...(selected && {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
      transform: 'translateX(4px) scale(1.01)',
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
      transform: 'scale(1.1)',
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
}));

export const SettingsListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
}));

export const SettingsContentTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  flexGrow: 1,
}));

export const SettingsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  flexGrow: 1, // Allow the title to take available space
  textAlign: 'center', // Center the text horizontally
}));

export const SettingsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  height: '48px',
  position: 'sticky',
  top: 0,
  zIndex: 1200,
  boxShadow: `0 1px 2px ${alpha(theme.palette.common.black, 0.05)}`,
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    background: theme.palette.background.paper,
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.1)}`,
  },
}));

export const SettingsListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 40,
  color: theme.palette.text.secondary,
  '.MuiListItem-root.Mui-selected &': {
    color: theme.palette.primary.main,
  },
}));

export const SettingsFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const SettingsFormSection = styled(Box)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.6),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  backdropFilter: 'blur(8px)',
  boxShadow: `0 1px 3px ${alpha(theme.palette.common.black, 0.1)}`,
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&.scrollable': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > div': {
      flexGrow: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      '& > div:first-of-type': {
        flexGrow: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          display: 'block',
        },
        '&::-webkit-scrollbar-track': {
          background: alpha(theme.palette.text.secondary, 0.05),
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: alpha(theme.palette.text.secondary, 0.2),
          borderRadius: '4px',
          '&:hover': {
            background: alpha(theme.palette.text.secondary, 0.3),
          },
        },
        scrollbarWidth: 'thin',
        scrollbarColor: `${alpha(theme.palette.text.secondary, 0.2)} ${alpha(theme.palette.text.secondary, 0.05)}`,
      },
    },
  },
}));

export const SettingsFormTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.1rem',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

export const SettingsFormDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(3),
}));

export const SettingsActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

export const SettingsTable = styled(TableContainer)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.5),
  borderRadius: theme.shape.borderRadius,
  backdropFilter: 'blur(8px)',
  marginTop: theme.spacing(2),
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
  },
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
  '& .MuiTableRow-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    transition: theme.transitions.create(['transform', 'box-shadow', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    // '&:hover': {
    //   backgroundColor: theme.palette.background.paper,
    //   transform: 'translateY(-1px) scale(1.001)',
    //   boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
    // },
  },
}));

export const SettingsPagination = styled(Pagination)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPaginationItem-root': {
    transition: theme.transitions.create(['transform', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
}));

export const SettingsToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.12)}`,
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const SettingsToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(
    ['background-color', 'box-shadow', 'transform'],
    { duration: theme.transitions.duration.shorter }
  ),
  '&:hover': {
    transform: 'translateY(-1px)',
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
}));

export const NotificationItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  transition: theme.transitions.create(
    ['transform', 'box-shadow', 'opacity', 'background-color'],
    { duration: theme.transitions.duration.shorter }
  ),
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const NotificationIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 40,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create(['color', 'transform'], {
    duration: theme.transitions.duration.shorter,
  }),
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
}));

export const NotificationContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
}));

export const NotificationText = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: 0,
  '& .notification-title': {
    fontWeight: 'inherit',
    fontSize: '0.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  '& .notification-content': {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '2px',
  },
}));

export const NotificationActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(2),
  '& .MuiIconButton-root': {
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: '1.125rem',
    },
  },
}));
