import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { ApiErrorResponse } from '../errors/error';
import { ErrorCode } from '../errors/errorCodes';
import { mapErrorResponse } from '../errors/errorUtils';
import { getStore } from '../utils/storeInjector';
import { logoutDirect } from '../store/authSlice';
import { CONFIG } from '../config';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  skipAuth?: boolean;
}

export interface CustomRequestConfig extends AxiosRequestConfig {
  skipAuth?: boolean;
}

// Create axios instance with base configuration
export const axiosInstance = axios.create({
  baseURL: CONFIG.API_URL,
  withCredentials: true,
  timeout: 300000, // 300 second timeout
});

// Request interceptor for authentication
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const customConfig = config as CustomAxiosRequestConfig;
    const token = getStore().getState().auth.accessToken;
    
    if (!customConfig.skipAuth && token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiErrorResponse>) => {
    // Handle network errors or non-response scenarios
    if (!error.response) {
      const networkError = {
        status: 500,
        error: 'NetworkError',
        message: 'Unable to connect to the server. Please check your internet connection.',
        errorCode: ErrorCode.SERVER_ERROR
      };
      return Promise.reject(mapErrorResponse(networkError));
    }

    const errorResponse = error.response.data;
    console.log('Axios interceptor error response:', errorResponse);

    // If the error response is not in the expected format
    if (!errorResponse || !errorResponse.errorCode) {
      const unknownError = {
        status: error.response.status,
        error: 'UnknownError',
        message: 'An unexpected error occurred.',
        errorCode: ErrorCode.SERVER_ERROR
      };
      return Promise.reject(mapErrorResponse(unknownError));
    }

    // Map the error response to include frontend configurations
    const mappedError = mapErrorResponse(errorResponse);

    // Handle authentication errors
    if (mappedError.errorCode === ErrorCode.AUTHENTICATION_ERROR) {
      // Log out the user
      await getStore().dispatch(logoutDirect());
      
      // Enhance the error message for the user
      mappedError.message = 'Your session is not authenticated. Please login again.';
      return Promise.reject(mappedError);
    }

    // For all other errors, reject with the mapped error
    return Promise.reject(mappedError);
  }
);

// Configuration object for requests that don't need authentication
export const skipAuthConfig: CustomRequestConfig = {
  skipAuth: true
};

export default axiosInstance;