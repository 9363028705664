import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { settingsSections } from './settingsConfig';
import {
  SettingsDialog,
  SettingsDialogContent,
  SettingsContainer,
  SettingsSidebar,
  SettingsHeader,
  SettingsTitle,
  SettingsSection,
  SettingsSectionTitle,
  SettingsList,
  SettingsListItem,
  SettingsListItemIcon,
  SettingsListItemText,
} from './SettingsComponents';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeSettings } from '../../store/settingsSlice';
import { RootState } from '../../store/store';
import SettingsRouter from './SettingsRouter';

const SettingsLayout: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isOpen = useSelector((state: RootState) => state.settings.isOpen);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Internal state for active section
  const [activeSection, setActiveSection] = useState(() => {
    const path = location.pathname.replace('/settings', '');
    return path || '/profile';
  });

  const handleClose = useCallback(() => {
    dispatch(closeSettings());
    navigate('/', { replace: true });
  }, [dispatch, navigate]);

  const handleNavigation = useCallback((path: string) => {
    setActiveSection(path.replace('/settings', ''));
    navigate(path, { replace: true });
  }, [navigate]);

  // Sync URL with internal state
  useEffect(() => {
    const path = location.pathname.replace('/settings', '');
    if (path !== activeSection) {
      setActiveSection(path || '/profile');
    }
  }, [location.pathname, activeSection]);

  const isRoot = activeSection === '/profile';

  const handleBack = useCallback(() => {
    if (isMobile && !isRoot) {
      handleNavigation('/settings/profile');
    }
  }, [isMobile, isRoot, handleNavigation]);

  const memoizedSections = useMemo(() => (
    settingsSections.map((section) => (
      <SettingsSection key={section.title}>
        <SettingsSectionTitle variant="subtitle2">
          {section.title}
        </SettingsSectionTitle>
        <SettingsList>
          {section.items.map((item) => (
            <SettingsListItem
              key={item.id}
              selected={activeSection === item.path}
              onClick={() => handleNavigation(`/settings${item.path}`)}
            >
              <SettingsListItemIcon>
                <item.icon />
              </SettingsListItemIcon>
              <SettingsListItemText primary={item.label} />
            </SettingsListItem>
          ))}
        </SettingsList>
      </SettingsSection>
    ))
  ), [activeSection, handleNavigation]);

  const MemoizedRouter = React.memo(SettingsRouter);

  if (!isOpen) return null;

  return (
    <SettingsDialog 
    open={isOpen} 
    onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
        handleClose();
        }
    }}
    disableEscapeKeyDown
    fullWidth 
    maxWidth="md"
    keepMounted
    TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: {
        enter: 250,
        exit: 200,
        },
        onExited: () => {
        navigate('/', { replace: true });
        }
    }}
    >
      <SettingsHeader>
        {isMobile && !isRoot && (
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <SettingsTitle variant="h6">
          {isMobile && !isRoot ? 
            settingsSections.flatMap(s => s.items).find(i => i.path === activeSection)?.label 
            : 'Settings'
          }
        </SettingsTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </SettingsHeader>

      <SettingsDialogContent>
        <SettingsContainer>
          {(!isMobile || isRoot) && (
            <SettingsSidebar>
              {memoizedSections}
            </SettingsSidebar>
          )}
          
          {(!isMobile || !isRoot) && (
            <MemoizedRouter />
          )}
        </SettingsContainer>
      </SettingsDialogContent>
    </SettingsDialog>
  );
};

export default React.memo(SettingsLayout);