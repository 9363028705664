import {
    PersonOutline,
    AccountCircleOutlined,
    NotificationsOutlined,
    // SmartToyOutlined,
    // RequestQuoteOutlined,
    // TerminalOutlined,
    // SettingsOutlined,
    BarChartOutlined,
    ShoppingCartOutlined,
    ShareOutlined,
    HistoryOutlined,
    HelpCenterOutlined,
    MenuBookOutlined,
    SupportOutlined,
  } from '@mui/icons-material';
  
  interface SettingsItem {
    id: string;
    path: string;
    label: string;
    icon: typeof PersonOutline;
  }
  
  interface SettingsSection {
    title: string;
    items: SettingsItem[];
  }
  
  export const settingsSections: SettingsSection[] = [
    {
      title: 'General',
      items: [
        { id: 'profile', path: '/profile', label: 'Profile', icon: PersonOutline },
        { id: 'account', path: '/account', label: 'Account', icon: AccountCircleOutlined },
        { id: 'notifications', path: '/notifications', label: 'Notifications', icon: NotificationsOutlined },
      ],
    },
    // {
    //   title: 'AI',
    //   items: [
    //     { id: 'models', path: '/models', label: 'Models', icon: SmartToyOutlined },
    //     { id: 'rate-card', path: '/pricing', label: 'Rate card', icon: RequestQuoteOutlined },
    //     { id: 'system-prompts', path: '/settings/system-prompts', label: 'System prompts', icon: TerminalOutlined },
    //     { id: 'advanced-settings', path: '/settings/advanced', label: 'Advanced settings', icon: SettingsOutlined },
    //   ],
    // },
    {
      title: 'Credits & Billing',
      items: [
        { id: 'usage', path: '/usage', label: 'Usage stats', icon: BarChartOutlined },
        { id: 'billing', path: '/billing', label: 'Buy credits', icon: ShoppingCartOutlined },
        { id: 'transfer', path: '/transfer', label: 'Transfer credits', icon: ShareOutlined },
        { id: 'activity', path: '/activity', label: 'Activity log', icon: HistoryOutlined },
      ],
    },
    {
      title: 'Help & FAQs',
      items: [
        { id: 'faqs', path: '/faqs', label: 'FAQs', icon: HelpCenterOutlined },
        { id: 'documentation', path: '/documentation', label: 'Documentation', icon: MenuBookOutlined },
        { id: 'support', path: '/support', label: 'Support', icon: SupportOutlined },
      ],
    },
  ];
  
  export const getSettingsByPath = (path: string): SettingsItem | undefined => {
    for (const section of settingsSections) {
      const item = section.items.find(item => item.path === path);
      if (item) return item;
    }
    return undefined;
  };