import { Box, CircularProgress, LinearProgress, Typography, useTheme } from '@mui/material';
import { keyframes } from '@mui/material/styles';


interface LoadingIndicatorProps {
  size?: 'small' | 'medium' | 'large';
  message?: string;
  variant?: 'circular' | 'pulse' | 'linear';
  position?: 'fixed' | 'absolute' | 'relative';
  blur?: boolean;
  transparent?: boolean;
}

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    background-position: 200% 0;
    opacity: 0.8;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.7);
    box-shadow: 0 0 0 0 rgba(0, 160, 224, 0.4);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 12px rgba(0, 160, 224, 0);
  }
  100% {
    transform: scale(0.7);
    box-shadow: 0 0 0 0 rgba(0, 160, 224, 0);
  }
`;

const LoadingIndicator = ({ 
  size = 'medium',
  message = 'Loading',
  variant = 'circular',
  position = 'fixed',
  blur = true,
  transparent = false
}: LoadingIndicatorProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: position,
        ...(position === 'fixed' && {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }),
        zIndex: theme.zIndex.modal + 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(3),
          gap: theme.spacing(2),
          ...theme.glassmorphism.light,
          backgroundColor: transparent 
            ? 'rgba(255, 255, 255, 0.6)'
            : 'rgba(255, 255, 255, 0.9)',
          borderRadius: '16px',
          transition: theme.animation.transition.medium,
          minWidth: 160,
          backdropFilter: blur ? 'blur(10px)' : 'none',
          border: `1px solid ${theme.palette.primary.light}20`,
          boxShadow: `0 8px 32px 0 ${theme.palette.primary.main}15`,
          
          '&:hover': {
            backgroundColor: transparent
              ? 'rgba(255, 255, 255, 0.7)'
              : 'rgba(255, 255, 255, 0.95)',
            // transform: 'translateY(-2px)',
            boxShadow: `0 12px 40px 0 ${theme.palette.primary.main}25`,
          }
        }}
      >
        {variant === 'circular' && (
          <CircularProgress
            size={size === 'small' ? 32 : size === 'large' ? 48 : 40}
            thickness={4}
            sx={{
              color: theme.palette.primary.main,
              '& .MuiCircularProgress-circle': {
                strokeLinecap: 'round',
                transition: theme.animation.transition.medium,
              }
            }}
          />
        )}
        
        {variant === 'pulse' && (
          <Box
            sx={{
              width: size === 'small' ? 32 : size === 'large' ? 48 : 40,
              height: size === 'small' ? 32 : size === 'large' ? 48 : 40,
              borderRadius: '50%',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
              animation: `${pulseAnimation} 2s ${theme.animation.microInteraction.easing} infinite`,
              boxShadow: `0 0 20px ${theme.palette.primary.main}40`
            }}
          />
        )}
        
        {variant === 'linear' && (
          <Box sx={{ width: '100%', minWidth: 240 }}>
            <LinearProgress
              sx={{
                height: size === 'small' ? 4 : size === 'large' ? 8 : 6,
                borderRadius: '8px',
                backgroundColor: `${theme.palette.primary.main}15`,
                '& .MuiLinearProgress-bar': {
                  borderRadius: '8px',
                  backgroundImage: `linear-gradient(
                    90deg,
                    ${theme.palette.primary.light} 0%,
                    ${theme.palette.primary.main} 50%,
                    ${theme.palette.primary.light} 100%
                  )`,
                  backgroundSize: '200% 100%',
                  animation: `${shimmerAnimation} 2.5s infinite`,
                }
              }}
            />
          </Box>
        )}
        
        {message && (
          <Typography
            variant="body2"
            sx={{ 
              color: theme.palette.text.secondary,
              fontWeight: 500,
              opacity: 0.9,
              transition: theme.animation.transition.medium,
              textAlign: 'center',
              fontSize: size === 'small' ? '0.875rem' : '1rem',
              letterSpacing: '0.01em'
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LoadingIndicator;